<template>
  <div>
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      redirect="/templates/contracts"
    />

    <v-video-modal
      name="overview"
      title="Contract template"
      description="A video to help you get started."
      step="contract-template"
      thumbnail="templates.png"
      embed="GhiKrbgdQts"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <div class="max-w-lg mx-auto px-4">
      <div class="flex -mx-2 mt-24 mb-10">
        <div
          v-show="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-show="!loading"
          id="lead-form-setting"
          class="w-full px-2"
        >
          <div class="bg-white px-8 py-8 rounded">
            <div class="flex items-center justify-between mb-2">
              <div class="w-1/2 md:w-2/3">
                <h1 class="text-xl">
                  Contract template
                </h1>
              </div>
              <div class="w-1/2 md:w-1/3 text-right">
                <div class="inline-block">
                  <v-tutorial-button @click="showTutorialVideoModal" />
                </div>
              </div>
            </div>
            <div class="input-group">
              <label>Template name</label>
              <input
                v-model="name"
                v-validate="{ required: true }"
                type="text"
                class="w-full"
                name="name"
              >
              <span class="error">{{ errors.first('name') }}</span>
            </div>
          </div>
          <div class="bg-white mt-4 px-8 pt-4 pb-8">
            <v-notification
              content="This agreement template is provided for your convenience. It is not provided as a substitute for legal advice. If you have any questions about this template or your finished contract, please contact a licensed attorney."
              :hide-close="true"
            />
            <!-- <v-editor
              :allow-token="false"
              :update-pending.sync="editorUpdatePending"
              :editor-content.sync="content"
            /> -->
            <v-ckeditor-token v-model="content" />
            <span class="error">{{ errors.first('content') }}</span>
          </div>
          <div class="mt-5 mb-10 text-right">
            <v-button
              v-show="!isSaving"
              @click="save"
            >
              Save
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
	data(){
  	return {
      metaTitle: 'Loading',
      isSaving: false,
      isSaved: false,
      loading: true,
      editorUpdatePending: false,
      contractId: null,
      name: '',
      content: '',
      user: auth.user(),
      isShowTutorialVideoModal: false,
    }
  },
  async mounted(){
    if(this.$route.params.templateId){
      await this.getContract(this.$route.params.templateId)
      this.metaTitle = `Template Contract: ${this.name}`
    } else {
      this.metaTitle = 'New Template Contract'
    }
    this.loading = false
  },
	methods: {
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    save() {
      this.$validator.validate().then(result => {
        if(result){
          this.doSave()
        }
      })
    },
    async doSave() {

      this.saving = true
      try {
        const postData = {
          companyId: this.user.company.id,
          contractId: this.contractId,
          name: this.name,
          content: this.content,
        }

        const { data } = await this.$api.get('templateContract').updateOrCreate(postData)

        this.contractId = data.contract_id
        this.isSaved = true
        this.saving = false

        this.$toasted.global.general_success({
          message : 'Contract template updated.'
        })

      } catch(e){
        logException(e)
      }
    },
    changeView(view){
      this.view = view
    },
    async getContract(id){
      try {
        const { data } = await this.$api.get('templateContract').detail(this.user.company.id, id)
        this.contractId = data.contract_id
        this.name = data.name
        this.content = data.content
        this.loading = false
        this.editorUpdatePending = true
      } catch(e){
        logException(e)
      }
    },
	},
}
</script>
